import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Row,
  Col,
  Spin,
  message,
  Checkbox,
  Input,
  Select,
  DatePicker,
  InputNumber,
} from "antd";
import { useLocation } from "react-router-dom";
import "./App.css";
import AdvancedForm from "./Complex";
import SimpleForm from "./Simple";
import FinishRedirect from "./FinishRedirect";
import Spearfisher from "./Spearfisher";
import GigWorker from "./GigWorker";
import AggregationGigWorker from "./AggregationGigWorker";
import AgentPanel from "./AgentPanel";

function App() {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const mode = query.get("mode");

  if (mode === "advanced") {
    return <AdvancedForm />;
  } else if (mode === "gig") {
    return <GigWorker />;
  } else if (mode === "agg_gig") {
    return <AggregationGigWorker />;
  } else if (mode === "agent") {
    return <AgentPanel />;
  } else if (mode === "simple") {
    return <SimpleForm />;
  } else if (mode === "finish") {
    return <FinishRedirect />;
  } else if (mode === "spearfisher") {
    return <Spearfisher />;
  } else {
    return "Invalid mode";
  }
}

export default App;
